<template>
	<table class="performance-list" v-for="(item, index) in gamePerformanceList" :key="index" :value="item.id">
		<tr>
			<th>Title</th>
			<td>{{ item?.title }}</td>
		</tr>
		<tr>
			<th>Nominal RTP</th>
			<td>{{ item?.nominalRtp }}</td>
		</tr>
		<tr>
			<th>Denom</th>
			<td>{{ item?.denomAU }}</td>
		</tr>
		<tr>
			<th>Games</th>
			<td>{{ item?.countPlays }}</td>
		</tr>
		<tr>
			<th>Wins</th>
			<td>{{ item?.countWins }}</td>
		</tr>
		<tr>
			<th>Progs</th>
			<td>{{ item?.countProgressives }}</td>
		</tr>
		<tr>
			<th>Wager Regular</th>
			<td>{{ systemCurrencyTool.formatFromAU(item?.wagerRegularAU, systemFull) }}</td>
		</tr>
		<tr>
			<th>Wager Promo</th>
			<td>{{ systemCurrencyTool.formatFromAU(item?.wagerPromoA, systemFull) }}</td>
		</tr>
		<tr>
			<th>Prog Contribution</th>
			<td>{{ systemCurrencyTool.formatFromAU(item?.progressiveContributionsAU, systemFull) }}</td>
		</tr>
		<tr>
			<th>Paid Paytable</th>
			<td>{{ systemCurrencyTool.formatFromAU(item?.paytablePrizeAU, systemFull) }}</td>
		</tr>
		<tr>
			<th>Paid Prog</th>
			<td>{{ systemCurrencyTool.formatFromAU(item?.progressivePrizeAU, systemFull) }}</td>
		</tr>
		<tr>
			<th>Paytable RTP%</th>
			<td>{{ calculatePaytableRTP(item) }}</td>
		</tr>
		<tr>
			<th>Actual RTP%</th>
			<td>{{ calculateActualRTP(item) }}</td>
		</tr>
	</table>
</template>

<script>
export default {
	name: "GamePerformanceTableMobile",
	inheritAttrs: false,
	props: {
		gamePerformanceList: Array,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
	},
	data() {
		return {
			systemMinorWholeOrFull: this.systemCurrencyTool.displayType.minorWholeOrFull,
			systemFull: this.systemCurrencyTool.displayType.full,
		};
	},
	methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.transfers-list {
	width: 100%;
	text-align: left;
	border-collapse: collapse;
	margin-bottom: 2em;
}

.transfers-list th,
.transfers-list td {
	border: 1px #000 solid;
	padding: 5px 10px;
}

.transfers-list th {
	width: 6em;
}

.transfers-list tr:nth-child(2n) {
	background-color: #bfbfbf;
}
</style>
