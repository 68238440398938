<template>
	<div id="filter-container">
		<div class="filter-options">
			<label for="startDate">Start Date</label>
			<input
				id="startDate"
				:class="errorState ? 'date-error' : ''"
				:min="startMin"
				:max="startMax"
				type="date"
				placeholder="Start Date"
				title="Start Date"
				v-model="startDate"
			/>
			<label for="endDate">End Date</label>
			<input
				id="endDate"
				:class="errorState ? 'date-error' : ''"
				:min="endMin"
				:max="endMax"
				type="date"
				placeholder="End Date"
				title="End Date"
				v-model="endDate"
			/>
			<button @click="applyFilters()" title="Apply date range" class="apply-filters btn">Apply Date</button>
			<button @click="exportReport()" title="Export entire report" class="apply-filters btn">Export Report</button>
		</div>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";

export default {
	name: "FilterDate",
	props: {
		dateDefaults: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			timeZoneOffset: new Date().getTimezoneOffset() / 60,
			filters: {},
			errorState: false,
			startDate: this.dateDefaults.startDate,
			endDate: this.dateDefaults.endDate,
			startMin: null,
			startMax: this.dateDefaults.endDate,
			endMin: this.dateDefaults.startDate,
			endMax: null,
		};
	},
	watch: {
		startDate() {
			this.endMin = this.startDate;
			this.endMax = null;
		},
		endDate() {
			this.startMax = this.endDate;
		},
	},
	created() {
		let thisInstance = this;
		this.eventBus.on("resetFilterComponent", () => {
			thisInstance.clearFilters();
		});
		onBeforeUnmount(() => {
			thisInstance.eventBus.off("resetFilterComponent");
		});
	},
	methods: {
		exportReport() {
			this.eventBus.emit("exportExcel");
		},
		throwDateTimeError(input) {
			this.status.message = `${input} is not complete. Must have both the date and time or be left unset/empty.`;
			this.status.ok = false;
			this.eventBus.emit("updateStatus", this.status);
		},
		applyFilters() {
			let errState = false;
			this.filters = {
				startDate: this.startDate ? this.startDate : null,
				endDate: this.endDate ? this.endDate : null,
			};

			if (document.getElementById("startDate")?.validity.badInput) {
				this.throwDateTimeError("Start Date");
				errState = true;
			}

			if (document.getElementById("endDate")?.validity.badInput) {
				this.throwDateTimeError("End Date");
				errState = true;
			}

			if (errState) return false;

			this.eventBus.emit("applyFilters", this.filters);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.filter-options input,
.filter-options select {
	flex: 0 1 15em;
	padding: 0;
}

input,
label,
select,
button {
	margin: 5px;
	align-self: center;
}

.filter-options select {
	box-sizing: content-box;
}

.clear-item {
	align-self: center;
	justify-self: center;
	background: #5a87bf;
	padding: 0px 5px 2px;
	margin: 0;
	border-radius: 100%;
	cursor: pointer;
	font-size: 0.8em;
}

.date-error {
	border: 3px red solid;
}
@media (min-width: 768px) {
	.filter-options {
		padding: 15px;
	}

	.filter-options input,
	.filter-options select {
		flex: 0 0 15em;
		padding: 0;
	}

	input,
	label,
	select,
	button {
		margin: 5px 10px;
	}
}
</style>
