<template>
	<div id="cashier-container">
		<div v-if="!serverComplete" id="loading">
			<span class="loading-icon"></span>
			<span class="loading-message">{{ busyText }}</span>
		</div>
		<h2>
			<span v-if="selectedCasino?.name">{{ selectedCasino.name }}</span> Cashier Shift Summary Report
		</h2>
		<div id="cashier-report-filters">
			<FilterDateTime :dateDefaults="dateDefaults" />
		</div>
		<div class="casino-selector">
			<button id="refresh" title="Refresh play list" class="btn" @click="getCashierShiftSummary()">Refresh</button>
			<label for="casinoOptions" v-if="casinoListForReports.length > 1">Casino</label>
			<select title="Select Casino" id="casinoOptions" v-model="selectedCasinoId" v-if="casinoListForReports.length > 1">
				<option v-for="(casino, index) in casinoListForReports" :key="index" :value="casino.id">{{ casino.name }}</option>
			</select>
			<div class="date">{{ dateTime.toLocaleTimeString([], this.shortDateOptions) }}</div>
			<button @click="exportExcel()" title="Export Report" class="apply-filters btn">Export Report</button>
		</div>
		<CashierShiftTable :summaryList="summaryList" :inGameCurrencyTool="inGameCurrencyTool" :systemCurrencyTool="systemCurrencyTool" />
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
import sharedScripts from "@/dependencies/sharedScripts";
import xlsxPerformStyles from "@/dependencies/xlsxPerformStyles";
import CashierShiftTable from "@/components/CashierShiftTable";
import FilterDateTime from "@/components/FilterDateTime";

export default {
	name: "CashierShiftSummaryReport",
	components: {
		CashierShiftTable,
		FilterDateTime,
	},
	props: {
		reporterState: Object,
		casinoList: Array,
		isMobile: Boolean,
		serverVersion: Object,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			selectedCasino: {},
			selectedCasinoId: this.reporterState.casinoId || this.selectedCasino.id,
			serverComplete: true,
			dateTime: new Date(),
			timeZoneOffset: new Date().getTimezoneOffset() / 60,
			dateDefaults: {},
			excelStartTime: 0,
			excelEndTime: 0,
			summaryList: [],
			casinoListForReports: [],
			selectedCasino: {},
			cashierUserId: null,
			shiftNumber: null,
			startDate: this.isoDayStart(),
			endDate: new Date().toISOString(),
			offset: null,
			selectedCasinoId: this.reporterState.casinoId,
			dateFmt: "[$-409]m/d/yy\ h:mm\ AM/PM;@",
			currFmt: '"$"#,##0.00_);[Red]\("$"#,##0.00\)',
			systemSymbol: this.systemCurrencyTool.currencyInfo.symbol,
			systemMinorWholeOrFull: this.systemCurrencyTool.displayType.minorWholeOrFull,
			systemFull: this.systemCurrencyTool.displayType.full,
		};
	},
	watch: {
		selectedCasinoId() {
			if (this.selectedCasinoId) {
				this.setSelectedCasino();
				this.getCashierShiftSummary();
			}
		},
	},
	created() {
		this.listCasinosForThisReporter();
		this.setExcellCurrencyFormat();

		let start = new Date(this.startDate);
		start = start.setHours(start.getHours() - this.timeZoneOffset);
		start = new Date(start).toISOString();
		let end = new Date();
		end = end.setHours(end.getHours() - this.timeZoneOffset);
		end = new Date(end).toISOString();
		this.dateDefaults = {
			startDate: start.slice(0, 16),
			endDate: end.slice(0, 16),
		};
		if (this.selectedCasinoId) {
			this.setSelectedCasino();
			this.getCashierShiftSummary();
		}
		this.eventBus.on("applyFilters", (payload) => {
			let error = false;
			if (!payload.startDate) {
				this.throwDateTimeError("Start Date");
				error = true;
			}
			if (!payload.endDate) {
				this.throwDateTimeError("End Date");
				error = true;
			}
			if (error) return;
			this.startDate = payload.startDate ? payload.startDate.slice(0, 16) : "";
			this.endDate = payload.endDate ? payload.endDate.slice(0, 16) : "";
			this.getCashierShiftSummary();
		});
		let thisInstance = this;
		onBeforeUnmount(() => {
			thisInstance.eventBus.off("applyFilters");
		});
	},
	methods: {
		setExcellCurrencyFormat() {
			let zeros = [];
			for (let index = 0; index < this.systemCurrencyTool.currencyInfo.minorDigits; index++) {
				zeros.push(0);
			}
			let decimalPlaces = this.systemCurrencyTool.currencyInfo.minorDigits > 0 ? `.${zeros.join("")}` : "";
			this.currFmt = `"${this.systemSymbol}"#,##0${decimalPlaces}_);[Red]\("${this.systemSymbol}"#,##0${decimalPlaces}\)`;
		},

		throwDateTimeError(input) {
			this.status.message = `${input} is not complete. ${input} is required. Must have both the date and time.`;
			this.status.ok = false;
			this.eventBus.emit("updateStatus", this.status);
		},
		listCasinosForThisReporter() {
			if (!this.reporterState?.isSysadmin) {
				let permissionsList = this.reporterState?.userPermissions?.sitePermissions;
				for (const key in permissionsList) {
					if (permissionsList[key].includes("SiteAdmin") || permissionsList[key].includes("Reporter")) {
						let casino = this.casinoList.filter((casino) => casino.id == key)[0];
						this.casinoListForReports.push(casino);
					}
				}
			} else {
				this.casinoListForReports = this.casinoList;
			}
		},
		setSelectedCasino() {
			this.selectedCasino = this.casinoListForReports.filter((casino) => casino.id == this.selectedCasinoId)[0];
		},
		async getCashierShiftSummary() {
			this.busyText = `Loading Cashier Shift Summary Report for ${this.selectedCasino.name}`;
			this.serverComplete = false;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.reporterState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL("/api/v1/report/cashier/shiftsummary", this.rabbitsfootHostUrl);
			let params = requestUrl.searchParams;

			if (this.selectedCasino.id) params.set("casinoId", this.selectedCasino.id);
			if (this.startDate) params.set("startDate", this.startDate);
			if (this.endDate) params.set("endDate", this.endDate);

			requestUrl.search = params.toString();

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverComplete = true;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.code === 409) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				this.summaryList = dataJson;
				this.dateTime = new Date();

				this.serverComplete = true;
				this.busyText = "";
			} catch (e) {
				this.serverComplete = true;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = e;
				console.error(e);
			}
		},
		async exportExcel() {
			if (this.summaryList.length === 0) {
				this.status.message = "Nothing to export";
				this.status.ok = false;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			this.fileNameDateRange = this.dateTime.toLocaleTimeString([], this.shortDateOptions).replace(", ", "_");
			this.excelStartTime = this.isoDateToExcelDate(this.summaryList.startDate, true);
			this.excelEndTime = this.isoDateToExcelDate(this.summaryList.endDate, true);

			const workbook = new this.ExcelJS.Workbook();
			workbook.creator = "Reporter App";
			workbook.lastModifiedBy = "Reporter App";
			workbook.created = new Date();
			workbook.modified = new Date();

			let contextRows = [
				[this.selectedCasino.name],
				["Cashier Shift Summary Report"],
				[this.serverVersion.serverName],
				["From:", this.excelStartTime],
				["To:", this.excelEndTime],
			];

			// header row
			const worksheet = workbook.addWorksheet("Cashier Shift Summary Report");

			worksheet.columns = [
				{ header: "Cashier Name", key: "cashierDisplayName", width: 25 },
				{ header: "Phone Number", key: "cashierPhoneNumber", width: 25 },
				{ header: "Bank Id", key: "cashierBankId", width: 15 },
				{ header: "Open Date/Time", key: "openDate", width: 25 },
				{ header: "Close Date/Time", key: "closeDate", width: 25 },
				{ header: "Opening Balance", key: "openingBalanceCashCOC", width: 25 },
				{ header: "Intermediate", key: "intermediateFillsCashCOC", width: 25 },
				{ header: "Closing Balance", key: "closingBalanceCashCOC", width: 25 },
				{ header: "To Players", key: "toPlayerCashCOC", width: 20 },
				{ header: "From Players", key: "fromPlayerCashCOC", width: 20 },
				{ header: "Granted", key: "toPlayerAccountsPromoCOC", width: 25 },
				{ header: "Reclaimed", key: "fromPlayerAccountsPromoCOC", width: 25 },
			];

			worksheet.insertRows(1, contextRows);
			worksheet.getCell("B4").numFmt = this.dateFmt;
			worksheet.getCell("B5").numFmt = this.dateFmt;

			worksheet.eachRow(function (row, rowNumber) {
				row.font = xlsxPerformStyles.defaultStyles.font;
			});

			worksheet.getColumn("openDate").numFmt = this.dateFmt;
			worksheet.getColumn("closeDate").numFmt = this.dateFmt;
			worksheet.getColumn("openingBalanceCashCOC").numFmt = this.currFmt;
			worksheet.getColumn("intermediateFillsCashCOC").numFmt = this.currFmt;
			worksheet.getColumn("closingBalanceCashCOC").numFmt = this.currFmt;
			worksheet.getColumn("toPlayerCashCOC").numFmt = this.currFmt;
			worksheet.getColumn("fromPlayerCashCOC").numFmt = this.currFmt;
			worksheet.getColumn("toPlayerAccountsPromoCOC").numFmt = this.currFmt;
			worksheet.getColumn("fromPlayerAccountsPromoCOC").numFmt = this.currFmt;

			let rowOffset = worksheet.rowCount;

			this.setStylesForCells(worksheet, xlsxPerformStyles.headerStyles, rowOffset);

			this.summaryList.banks.forEach((bank, index) => {
				let data = {};

				data.cashierDisplayName = bank.cashierDisplayName;
				data.cashierPhoneNumber = bank.cashierPhoneNumber;
				data.cashierBankId = bank.cashierBankId;
				data.openDate = this.isoDateToExcelDate(bank.openDate, true);
				data.closeDate = bank.closeDate ? this.isoDateToExcelDate(bank.closeDate, true) : "Bank still open";
				data.openingBalanceCashCOC = bank.openingBalanceCashCOC;
				data.intermediateFillsCashCOC = bank.intermediateFillsCashCOC;
				data.closingBalanceCashCOC = bank.closingBalanceCashCOC;
				data.toPlayerCashCOC = bank.toPlayerCashCOC;
				data.fromPlayerCashCOC = bank.fromPlayerCashCOC;
				data.toPlayerAccountsPromoCOC = this.systemCurrencyTool.toCurrencyFromAU(bank.toPlayerAccountsPromoAU);
				data.fromPlayerAccountsPromoCOC = this.systemCurrencyTool.toCurrencyFromAU(bank.fromPlayerAccountsPromoAU);

				worksheet.addRow(data);
				rowOffset = worksheet.rowCount;

				// Rows are not zero indexed and also need to skip header row so, index + rowOffset
				this.setStylesForCells(worksheet, xlsxPerformStyles.basicStyles, rowOffset);

				// Fill every even numbered row after header row.
				// Rows are not zero indexed so we actually are selecting for odd since we want the even rows AFTER header
				if (index % 2 !== 0) this.setSingleStyleForCells(worksheet, "fill", xlsxPerformStyles.oddFill, rowOffset);
			});

			worksheet.addRow();
			rowOffset = worksheet.rowCount;
			this.setStylesForCells(worksheet, xlsxPerformStyles.headerStyles, rowOffset);

			worksheet.getColumn(1).alignment = "left";
			worksheet.getColumn(2).alignment = "left";
			worksheet.getColumn(3).alignment = "left";

			workbook.xlsx
				.writeBuffer()
				.then((data) => {
					const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" });
					saveAs(blob, `Cashier_Shift_Summary_Report_${this.fileNameDateRange}.xlsx`);
				})
				.catch((e) => {
					this.status.message = `Failed to exprort spreadsheet: ${e}`;
					this.status.ok = false;
					this.eventBus.emit("updateStatus", this.status);
					this.serverComplete = true;
					console.error(e);
					return false;
				});
		},
	},
};
</script>

<style>
#cashier-report-filters .export {
	display: none;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#loading {
	display: grid;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 30px;
	background-color: rgb(0 0 0 / 70%);
	z-index: 9999;
	transition: background-color 0.3s ease-in-out;
	text-align: center;
	align-content: center;
}

.casino-selector {
	display: flex;
	justify-content: center;
	align-items: center;
}

#filter-container {
	display: flex;
	justify-content: center;
}
</style>
