<template>
	<div id="cashier-shift-container">
		<table class="cashier-shift-list" v-if="summaryList?.banks?.length > 0">
			<tr class="header-row sticky">
				<th>Cashier Name</th>
				<th>Phone Number</th>
				<th>Bank Id</th>
				<th>Open Date/Time</th>
				<th>Close Date/Time</th>
				<th>Opening Balance</th>
				<th>Intermediate</th>
				<th>Closing Balance</th>
				<th>To Player Cash</th>
				<th>From Player Cash</th>
			</tr>
			<tr class="data-row" v-for="(bank, index) in summaryList.banks" :key="index">
				<td>{{ bank.cashierDisplayName }}</td>
				<td>{{ bank.cashierPhoneNumber }}</td>
				<td>{{ bank.cashierBankId }}</td>
				<td>{{ new Date(bank.openDate).toLocaleTimeString([], shortDateOptions) }}</td>
				<td :class="bank.closeDate ? '' : 'bold-light-yellow center-align'">
					{{ bank.closeDate ? new Date(bank.closeDate).toLocaleTimeString([], shortDateOptions) : "Bank is open" }}
				</td>
				<td class="right-align">{{ systemCurrencyTool.formatCurrency(bank.openingBalanceCashCOC, systemFull) }}</td>
				<td class="right-align">{{ systemCurrencyTool.formatCurrency(bank.intermediateFillsCashCOC, systemFull) }}</td>
				<td class="right-align" :class="bank.closeDate ? '' : 'bold-light-yellow center-align'">
					{{ bank.closingBalanceCashCOC ? systemCurrencyTool.formatCurrency(bank.closingBalanceCashCOC, systemFull) : "Bank is open" }}
				</td>
				<td class="right-align">{{ systemCurrencyTool.formatCurrency(bank.toPlayerCashCOC, systemFull) }}</td>
				<td class="right-align">{{ systemCurrencyTool.formatCurrency(bank.fromPlayerCashCOC, systemFull) }}</td>
			</tr>
		</table>
		<div v-else>
			<h2 class="no-records">No Shift Records for this date range.</h2>
		</div>
	</div>
</template>

<script>
export default {
	name: "CashierShiftTable",
	inheritAttrs: false,
	props: {
		summaryList: Object,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
	},
	data() {
		return {
			systemMinorWholeOrFull: this.systemCurrencyTool.displayType.minorWholeOrFull,
			systemFull: this.systemCurrencyTool.displayType.full,
		};
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sticky {
	top: -16px;
}

#cashier-shift-container {
	position: relative;
	margin-bottom: 15px;
	padding: 15px;
	background-color: #ddd;
	white-space: break-spaces;
	overflow: auto;
}

.cashier-shift-list {
	width: 100%;
	font-size: 14px;
	text-align: left;
	border-collapse: collapse;
}

.cashier-shift-list th,
.cashier-shift-list td {
	border: 1px #000 solid;
	padding: 5px 10px;
}

.cashier-shift-list tr.data-row {
	color: #000;
}

.cashier-shift-list tr:nth-child(2n) {
	background-color: #bfbfbf;
}

.header-row {
	background-color: #525252;
	color: #fff;
}

.header-row th {
	border-color: #fff #fff #000;
}

.no-records {
	color: #000;
}

.right-align {
	text-align: right;
}

.left-align {
	text-align: left;
}

.center-align {
	text-align: center;
}
</style>
