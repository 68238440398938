<template>
	<div id="game-performance">
		<div class="game-performance-container">
			<div v-if="!serverComplete" id="performance-loading">
				<span class="loading-icon"></span>
				<span class="loading-message">{{ busyText }}</span>
			</div>
			<h2>
				<span v-if="selectedCasino?.name">{{ selectedCasino.name }}</span> Game Performance Report
			</h2>
			<div id="paging">
				<label for="casinoOptions" v-if="casinoListForReports.length > 1">Casino</label>
				<select title="Select Casino" id="casinoOptions" v-model="selectedCasinoId" v-if="casinoListForReports.length > 1">
					<option v-for="(casino, index) in casinoListForReports" :key="index" :value="casino.id">{{ casino.name }}</option>
				</select>
				<FilterDate :dateDefaults="dateDefaults" />
			</div>
			<div v-if="selectedCasinoId">
				<GamePerformanceTableMobile
					class="performance-list"
					:selectedCasino="selectedCasino"
					:gamePerformanceList="gamePerformanceList"
					:inGameCurrencyTool="inGameCurrencyTool"
					:systemCurrencyTool="systemCurrencyTool"
					v-if="isMobile"
				/>
				<GamePerformanceTable
					class="performance-list"
					:selectedCasino="selectedCasino"
					:gamePerformanceList="gamePerformanceList"
					:inGameCurrencyTool="inGameCurrencyTool"
					:systemCurrencyTool="systemCurrencyTool"
					v-if="!isMobile"
				/>
			</div>
			<h3 v-else>Please select a Casino</h3>
		</div>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
import sharedScripts from "@/dependencies/sharedScripts";
import xlsxPerformStyles from "@/dependencies/xlsxPerformStyles";
import GamePerformanceTableMobile from "@/components/GamePerformanceTableMobile";
import GamePerformanceTable from "@/components/GamePerformanceTable";
import FilterDate from "@/components/FilterDate";

export default {
	name: "GamePerformance",
	components: {
		GamePerformanceTableMobile,
		GamePerformanceTable,
		FilterDate,
	},
	props: {
		reporterState: Object,
		casinoList: Array,
		isMobile: Boolean,
		casinoListForReports: Array,
		serverVersion: Object,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			selectedCasino: {},
			selectedCasinoId: this.reporterState.casinoId || this.selectedCasino.id,
			gamePerformanceList: [],
			serverComplete: true,
			startDate: this.getLastArchiveWeekRange().start,
			endDate: this.getLastArchiveWeekRange().end,
			dateDefaults: {},
			excelStart: 0,
			excelEnd: 0,
			fileNameDateRange: `${this.startDate}_to_${this.endDate}`,
			dateFmt: "yyyy\-mm\-dd;@",
			currFmt: '"$"#,##0.00',
			systemSymbol: this.systemCurrencyTool.currencyInfo.symbol,
			systemMinorWholeOrFull: this.systemCurrencyTool.displayType.minorWholeOrFull,
			systemFull: this.systemCurrencyTool.displayType.full,
		};
	},
	watch: {
		selectedCasinoId() {
			if (this.selectedCasinoId) {
				this.setSelectedCasino();
				this.getGamePerformanceReport();
			}
		},
	},
	created() {
		this.setSelectedCasino();
		this.setExcellCurrencyFormat();
		this.dateDefaults = {
			startDate: this.startDate,
			endDate: this.endDate,
		};
		this.eventBus.on("applyFilters", (payload) => {
			this.applyFilters(payload);
		});
		this.eventBus.on("exportExcel", () => {
			this.exportExcel();
		});
		onBeforeUnmount(() => {
			this.eventBus.off("applyFilters");
			this.eventBus.off("exportExcel");
		});
	},
	mounted() {
		this.getGamePerformanceReport();
	},
	methods: {
		setExcellCurrencyFormat() {
			let zeros = [];
			for (let index = 0; index < this.systemCurrencyTool.currencyInfo.minorDigits; index++) {
				zeros.push(0);
			}
			let decimalPlaces = this.systemCurrencyTool.currencyInfo.minorDigits > 0 ? `.${zeros.join("")}` : "";
			this.currFmt = `"${this.systemSymbol}"#,##0${decimalPlaces}_);[Red]\("${this.systemSymbol}"#,##0${decimalPlaces}\)`;
		},
		setSelectedCasino() {
			this.selectedCasino = this.casinoListForReports.filter((casino) => casino.id == this.selectedCasinoId)[0];
		},
		applyFilters(filters) {
			this.fileNameDateRange = `${filters.startDate}_to_${filters.endDate}`;
			this.excelStart = this.isoDateToExcelDate(filters.startDate);
			this.excelEnd = this.isoDateToExcelDate(filters.endDate);
			this.startDate = this.addISOStringOffset(`${filters.startDate}T00:00`);
			this.endDate = this.addISOStringOffset(`${filters.endDate}T23:59`);

			let filterList = [];
			for (const filter in filters) {
				filterList.push(filters[filter]);
			}
			let nullOrEmpty = (item) => item === null || item.length <= 0;
			let anyNullOrEmpty = filterList.some(nullOrEmpty);

			if (anyNullOrEmpty) {
				this.status.message = "Start Date and End Date filters are required.";
				this.status.ok = false;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			if (this.selectedCasinoId) this.getGamePerformanceReport();
		},
		async getGamePerformanceReport() {
			this.busyText = `Loading Game Performance Report`;
			this.serverComplete = false;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.reporterState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL("/api/v1/report/game/performance", this.rabbitsfootHostUrl);
			let params = requestUrl.searchParams;

			if (this.selectedCasinoId) params.set("casinoId", this.selectedCasinoId);
			if (this.startDate) params.set("startDate", this.startDate);
			if (this.endDate) params.set("endDate", this.endDate);

			requestUrl.search = params.toString();

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverComplete = true;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.code === 409) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				this.gamePerformanceList = dataJson;

				this.serverComplete = true;
				this.busyText = "";
			} catch (e) {
				this.serverComplete = true;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = e;
				console.error(e);
			}
		},
		async exportExcel() {
			if (this.gamePerformanceList.length === 0) {
				this.status.message = "Nothing to export";
				this.status.ok = false;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			const workbook = new this.ExcelJS.Workbook();
			workbook.creator = "Reporter App";
			workbook.lastModifiedBy = "Reporter App";
			workbook.created = new Date();
			workbook.modified = new Date();

			let contextRows = [
				[`${this.selectedCasino.name}`],
				["Game Performance", this.serverVersion.serverName],
				["From:", this.excelStart],
				["To:", this.excelEnd],
			];

			// header row
			const worksheet = workbook.addWorksheet("Game Performance", {
				// freeze header rows (the + 1 is for row getting added at next insertRows)
				views: [{ state: "frozen", xSplit: 1, ySplit: contextRows.length + 1 }],
			});

			worksheet.columns = [
				{ header: "Game Title", key: "title", width: 24 },
				{ header: "Nominal RTP%", key: "nominalRtp", width: 21 },
				{ header: "Denom", key: "denomAU", width: 12 },
				{ header: "Games", key: "countPlays", width: 11 },
				{ header: "Wins", key: "countWins", width: 8 },
				{ header: "Progs", key: "countProgressives", width: 10 },
				{ header: "Wager Regular", key: "wagerRegularAU", width: 23 },
				{ header: "Wager Promo", key: "wagerPromoAU", width: 21 },
				{ header: "Prog Contribution", key: "progressiveContributionsAU", width: 27 },
				{ header: "Paid Paytable", key: "paytablePrizeAU", width: 21 },
				{ header: "Paid Prog", key: "progressivePrizeAU", width: 15 },
				{ header: "Paytable RTP%", key: "PaytableRTP", width: 24 },
				{ header: "Actual RTP%", key: "ActualRTP", width: 20 },
			];

			worksheet.insertRows(1, contextRows);
			worksheet.getCell("B3").numFmt = this.dateFmt;
			worksheet.getCell("B4").numFmt = this.dateFmt;

			worksheet.eachRow(function (row, rowNumber) {
				row.font = xlsxPerformStyles.defaultStyles.font;
			});

			worksheet.getColumn("wagerRegularAU").numFmt = this.currFmt;
			worksheet.getColumn("wagerPromoAU").numFmt = this.currFmt;
			worksheet.getColumn("progressiveContributionsAU").numFmt = this.currFmt;
			worksheet.getColumn("paytablePrizeAU").numFmt = this.currFmt;
			worksheet.getColumn("progressivePrizeAU").numFmt = this.currFmt;

			let rowOffset = worksheet.rowCount;

			this.setStylesForCells(worksheet, xlsxPerformStyles.headerStyles, rowOffset);

			this.gamePerformanceList.forEach((item, index) => {
				// need unique copy of performance report to keep from mutating the prop that's passed to child component
				let data = Object.assign({}, item);

				data.PaytableRTP = this.calculatePaytableRTP(data);
				data.ActualRTP = this.calculateActualRTP(data);
				data.wagerRegularAU = this.systemCurrencyTool.toCurrencyFromAU(data.wagerRegularAU);
				data.wagerPromoAU = this.systemCurrencyTool.toCurrencyFromAU(data.wagerPromoAU);
				data.progressiveContributionsAU = this.systemCurrencyTool.toCurrencyFromAU(data.progressiveContributionsAU);
				data.paytablePrizeAU = this.systemCurrencyTool.toCurrencyFromAU(data.paytablePrizeAU);
				data.progressivePrizeAU = this.systemCurrencyTool.toCurrencyFromAU(data.progressivePrizeAU);

				worksheet.addRow(data);

				rowOffset = worksheet.rowCount;

				// Rows are not zero indexed and also need to skip header row so, index + rowOffset
				this.setStylesForCells(worksheet, xlsxPerformStyles.basicStyles, rowOffset);

				// Fill every even numbered row after header row.
				// Rows are not zero indexed so we actually are selecting for odd since we want the even rows AFTER header
				if (index % 2 !== 0) this.setSingleStyleForCells(worksheet, "fill", xlsxPerformStyles.oddFill, rowOffset);
			});

			worksheet.getColumn(1).alignment = "left";

			workbook.xlsx
				.writeBuffer()
				.then((data) => {
					const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" });
					saveAs(blob, `Game_Performance_${this.fileNameDateRange}.xlsx`);
				})
				.catch((e) => {
					this.status.message = `Failed to exprort spreadsheet: ${e}`;
					this.status.ok = false;
					this.eventBus.emit("updateStatus", this.status);
					this.serverComplete = true;
					console.error(e);
					return false;
				});
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#performance-loading {
	display: grid;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 30px;
	background-color: rgb(0 0 0 / 70%);
	z-index: 9999;
	transition: background-color 0.3s ease-in-out;
	text-align: center;
	align-content: center;
}

.user-message {
	margin-top: 15px;
}

.casino-balances {
	width: 100%;
	text-align: left;
	border-collapse: collapse;
}

.casino-balances {
	margin-bottom: 30px;
}

.funds-locks {
	font-weight: bold;
	color: #8f0000;
}

.casino-balances th,
.casino-balances td {
	border: 1px #000 solid;
	padding: 5px 10px;
}

.casino-balances tr:nth-child(2n) {
	background-color: #bfbfbf;
}
</style>
