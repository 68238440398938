<template>
	<h3>Player Accounts</h3>
	<div class="table-container" v-if="playersList.length > 0">
		<table class="player-list">
			<tr class="header-row">
				<th>Last Login</th>
				<th># Players</th>
				<th>Regular Balance</th>
				<th>Promo Balance</th>
				<th>Locked funds</th>
			</tr>
			<tr class="data-row" v-for="(item, index) in playersList" :key="index">
				<td>
					{{ item.loginWithinDays < 999999 ? `Within ${item.loginWithinDays} day${item.loginWithinDays > 1 ? "s" : ""}` : `All Time` }}
				</td>
				<td>{{ item.playerCount }}</td>
				<td>{{ systemCurrencyTool.formatFromAU(item.balanceRegularAU, systemFull) }}</td>
				<td>{{ systemCurrencyTool.formatFromAU(item.balancePromoAU, systemFull) }}</td>
				<td>{{ systemCurrencyTool.formatFromAU(item.lockedFundsAU, systemFull) }}</td>
			</tr>
		</table>
	</div>
	<div v-else>
		<h2 class="no-records">No Player Account Reports</h2>
	</div>
</template>

<script>
export default {
	name: "PlayerAccountTable",
	inheritAttrs: false,
	props: {
		playersList: Array,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
	},
	data() {
		return {
			systemFull: this.systemCurrencyTool.displayType.full,
		};
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.table-container {
	position: relative;
	margin-bottom: 15px;
	padding: 15px;
	background-color: #ddd;
	margin-bottom: 30px;
}

.player-list {
	width: 100%;
	text-align: center;
	position: relative;
	padding: 15px;
	color: #000;
	background-color: #ddd;
}

.player-list th,
.player-list td {
	border: 1px #000 solid;
	padding: 5px 10px;
}

.player-list tr:nth-child(2n) {
	background-color: #bfbfbf;
}

.player-list tr.header-row {
	background-color: #525252;
	color: #fff;
}
</style>
