export default {
    defaultStyles: {
        font: {
            name: "Arial",
            color: { argb: "00000000" },
            size: 12,
        },
    },
    headerStyles: {
        font: {
            name: "Arial",
            color: { argb: "00000000" },
            size: 16,
            bold: true,
        },
        fill: {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFFF00" },
        },
        border: {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
        },
        alignment: {
            vertical: "middle",
            horizontal: "center",
        },
    },
    basicStyles: {
        font: {
            name: "Arial",
            color: { argb: "00000000" },
            family: 2,
            size: 12,
            bold: false,
        },
        border: {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
        },
        alignment: {
            vertical: "middle",
            horizontal: "right",
        },
    },
    totalStyles: {
        font: {
            name: "Arial",
            color: { argb: "FFFFFFFF" },
            size: 12,
            bold: true,
        },
        fill: {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "00000000" },
        },
        border: {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
        },
    },
    oddFill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "DDDDDDDD" },
    },
    center: {
        vertical: "middle",
        horizontal: "center",
    },
    left: {
        vertical: "middle",
        horizontal: "left",
    },
    casinoCell: {
        type: "pattern",
        pattern: "solid",
        fgColor: {
            theme: 0,
            tint: -0.34998626667073579,
        },
    },
    cashierCell: {
        type: "pattern",
        pattern: "solid",
        fgColor: {
            theme: 6,
            tint: 0.39997558519241921,
        },
    },
    mruCell: {
        type: "pattern",
        pattern: "solid",
        fgColor: {
            theme: 9,
            tint: 0.59999389629810485,
        },
    },
    webcell: {
        type: "pattern",
        pattern: "solid",
        fgColor: {
            theme: 8,
            tint: 0.49999389629810485,
        },
    },
    totalsCell: {
        type: "pattern",
        pattern: "solid",
        fgColor: {
            theme: 7,
            tint: 0.39997558519241921,
        },
    },
}
