export default {
    defaultStyles: {
        font: {
            name: "Arial",
            color: { argb: "00000000" },
            size: 12,
        },
    },
    headerStyles: {
        font: {
            name: "Arial",
            color: { argb: "FF0000FF" },
            size: 16,
            bold: true,
        },
        fill: {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFFF00" },
        },
        border: {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "medium" },
            right: { style: "thin" },
        },
        alignment: {
            vertical: "middle",
            horizontal: "center",
        },
    },
    basicStyles: {
        font: {
            name: "Arial",
            color: { argb: "00000000" },
            family: 2,
            size: 12,
            bold: false,
        },
        border: {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
        },
        alignment: {
            vertical: "middle",
            horizontal: "right",
        },
    },
    oddFill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "DDDDDDDD" },
    },
}
