<template>
	<h3>Progressive Pots</h3>
	<div class="table-container" v-if="progressivesList.length > 0">
		<table class="progressive-list">
			<tr class="header-row">
				<th>Family Name</th>
				<th>Value</th>
			</tr>
			<tr class="data-row" v-for="(item, index) in progressivesList" :key="index">
				<td>{{ item.potFamilyName }}</td>
				<td>{{ systemCurrencyTool.formatFromAU(item.valueAU, systemFull) }}</td>
			</tr>
		</table>
	</div>
	<div v-else>
		<h2 class="no-records">No Progressive Pots Reports</h2>
	</div>
</template>

<script>
export default {
	name: "ProgressivePotsTable",
	inheritAttrs: false,
	props: {
		progressivesList: Array,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
	},
	data() {
		return {
			systemFull: this.systemCurrencyTool.displayType.full,
		};
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.table-container {
	position: relative;
	margin-bottom: 15px;
	padding: 15px;
	background-color: #ddd;
	margin-bottom: 30px;
}

.progressive-list {
	width: 100%;
	text-align: center;
	position: relative;
	padding: 15px;
	color: #000;
	background-color: #ddd;
}

.progressive-list th,
.progressive-list td {
	border: 1px #000 solid;
	padding: 5px 10px;
}

.progressive-list tr:nth-child(2n) {
	background-color: #bfbfbf;
}

.progressive-list tr.header-row {
	background-color: #525252;
	color: #fff;
}
</style>
