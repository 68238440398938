<template>
	<div id="liability-container">
		<div v-if="!serverComplete" id="loading">
			<span class="loading-icon"></span>
			<span class="loading-message">{{ busyText }}</span>
		</div>
		<h2>
			<span v-if="selectedCasino?.name">{{ selectedCasino.name }} </span>Liability Report
		</h2>
		<div class="casino-selector">
			<button id="refresh" title="Refresh play list" class="btn" @click="getPlayerAccountLiability()">Refresh</button>
			<label for="casinoOptions" v-if="casinoListForReports.length > 1">Casino</label>
			<select title="Select Casino" id="casinoOptions" v-model="selectedCasinoId" v-if="casinoListForReports.length > 1">
				<option v-for="(casino, index) in casinoListForReports" :key="index" :value="casino.id">{{ casino.name }}</option>
			</select>
			<div class="date">{{ dateTime.toLocaleTimeString([], this.shortDateOptions) }}</div>
			<button @click="exportExcel()" title="Export liability report" class="apply-filters btn">Export Report</button>
		</div>
		<PlayerAccountTable :playersList="playersList" :inGameCurrencyTool="inGameCurrencyTool" :systemCurrencyTool="systemCurrencyTool" />
		<ProgressivePotsTable
			:progressivesList="progressivesList"
			:inGameCurrencyTool="inGameCurrencyTool"
			:systemCurrencyTool="systemCurrencyTool"
		/>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
import sharedScripts from "@/dependencies/sharedScripts";
import xlsxPerformStyles from "@/dependencies/xlsxPerformStyles";
import PlayerAccountTable from "@/components/PlayerAccountTable";
import ProgressivePotsTable from "@/components/ProgressivePotsTable";

export default {
	name: "LiabilityReport",
	components: {
		PlayerAccountTable,
		ProgressivePotsTable,
	},
	props: {
		reporterState: Object,
		casinoList: Array,
		isMobile: Boolean,
		casinoListForReports: Array,
		serverVersion: Object,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			selectedCasino: {},
			selectedCasinoId: this.reporterState.casinoId || this.selectedCasino.id,
			serverComplete: true,
			dateTime: new Date(),
			excelDateTime: 0,
			playersList: [],
			progressivesList: [],
			selectedCasino: {},
			selectedCasinoId: this.reporterState.casinoId,
			dateFmt: "[$-409]m/d/yy\ h:mm\ AM/PM;@",
			currFmt: '"$"#,##0.00_);[Red]\("$"#,##0.00\)',
			systemSymbol: this.systemCurrencyTool.currencyInfo.symbol,
			systemFull: this.systemCurrencyTool.displayType.full,
		};
	},
	watch: {
		selectedCasinoId() {
			if (this.selectedCasinoId) {
				this.setSelectedCasino();
				this.getPlayerAccountLiability();
				this.setExcellCurrencyFormat();
			}
		},
	},
	created() {
		if (this.selectedCasinoId) {
			this.setSelectedCasino();
			this.getPlayerAccountLiability();
			this.setExcellCurrencyFormat();
		}
	},
	methods: {
		setExcellCurrencyFormat() {
			let zeros = [];
			for (let index = 0; index < this.systemCurrencyTool.currencyInfo.minorDigits; index++) {
				zeros.push(0);
			}
			let decimalPlaces = this.systemCurrencyTool.currencyInfo.minorDigits > 0 ? `.${zeros.join("")}` : "";
			this.currFmt = `"${this.systemSymbol}"#,##0${decimalPlaces}_);[Red]\("${this.systemSymbol}"#,##0${decimalPlaces}\)`;
		},
		setSelectedCasino() {
			let thisInstance = this;
			this.selectedCasino = this.casinoListForReports.filter((casino) => casino.id == thisInstance.selectedCasinoId)[0];
		},
		async getPlayerAccountLiability() {
			this.busyText = `Loading Player Account Liability Report for ${this.selectedCasino.name}`;
			this.serverComplete = false;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.reporterState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL("/api/v1/report/liability/players", this.rabbitsfootHostUrl);
			let params = requestUrl.searchParams;

			if (this.selectedCasino.id) params.set("casinoId", this.selectedCasino.id);

			requestUrl.search = params.toString();

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverComplete = true;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.code === 409) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				this.playersList = dataJson;
				this.dateTime = new Date();

				this.getProgressivePots();

				this.serverComplete = true;
				this.busyText = "";
			} catch (e) {
				this.serverComplete = true;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = e;
				console.error(e);
			}
		},
		async getProgressivePots() {
			this.busyText = `Loading Progressive Pots Liability Report for ${this.selectedCasino.name}`;
			this.serverComplete = false;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.reporterState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL("/api/v1/report/liability/progressives", this.rabbitsfootHostUrl);
			let params = requestUrl.searchParams;

			if (this.selectedCasino.id) params.set("casinoId", this.selectedCasino.id);

			requestUrl.search = params.toString();

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverComplete = true;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.code === 409) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				this.progressivesList = dataJson;
				this.dateTime = new Date();

				this.serverComplete = true;
				this.busyText = "";
			} catch (e) {
				this.serverComplete = true;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = e;
				console.error(e);
			}
		},
		async exportExcel() {
			if (this.playersList.length === 0 && this.progressivesList.length === 0) {
				this.status.message = "Nothing to export";
				this.status.ok = false;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			this.fileNameDateRange = this.dateTime.toLocaleTimeString([], this.shortDateOptions).replace(", ", "_");
			this.excelDateTime = this.isoDateToExcelDate(this.dateTime, true);

			const workbook = new this.ExcelJS.Workbook();
			workbook.creator = "Reporter App";
			workbook.lastModifiedBy = "Reporter App";
			workbook.created = new Date();
			workbook.modified = new Date();

			let contextRows = [
				[`${this.selectedCasino.name}`],
				["Liability Report", this.serverVersion.serverName],
				["Date/Time:", this.excelDateTime],
			];

			// header row
			const worksheet = workbook.addWorksheet("Liability Report");

			worksheet.columns = [
				{ header: "Last Login", key: "loginWithinDays", width: 25 },
				{ header: "# Players", key: "playerCount", width: 25 },
				{ header: "Regular Balance", key: "balanceRegularAU", width: 25 },
				{ header: "Promo Balance", key: "balancePromoAU", width: 25 },
				{ header: "Locked funds", key: "lockedFundsAU", width: 25 },
			];

			worksheet.insertRows(1, contextRows);
			worksheet.getCell("B3").numFmt = this.dateFmt;

			worksheet.eachRow(function (row, rowNumber) {
				row.font = xlsxPerformStyles.defaultStyles.font;
			});

			worksheet.getColumn("balanceRegularAU").numFmt = this.currFmt;
			worksheet.getColumn("balancePromoAU").numFmt = this.currFmt;
			worksheet.getColumn("lockedFundsAU").numFmt = this.currFmt;

			let rowOffset = worksheet.rowCount;

			this.setStylesForCells(worksheet, xlsxPerformStyles.headerStyles, rowOffset);

			this.playersList.forEach((item, index) => {
				// need unique copy of sales report to keep from mutating the prop that's passed to child component
				let data = Object.assign({}, item);

				data.loginWithinDays =
					data.loginWithinDays < 999999 ? `Within ${data.loginWithinDays} day${data.loginWithinDays > 1 ? "s" : ""}` : `All Time`;
				data.balanceRegularAU = this.systemCurrencyTool.formatFromAU(data.balanceRegularAU, this.systemFull);
				data.balancePromoAU = this.systemCurrencyTool.formatFromAU(data.balancePromoAU, this.systemFull);
				data.lockedFundsAU = this.systemCurrencyTool.formatFromAU(data.lockedFundsAU, this.systemFull);

				worksheet.addRow(data);
				rowOffset = worksheet.rowCount;

				// Rows are not zero indexed and also need to skip header row so, index + rowOffset
				this.setStylesForCells(worksheet, xlsxPerformStyles.basicStyles, rowOffset);

				// Fill every even numbered row after header row.
				// Rows are not zero indexed so we actually are selecting for odd since we want the even rows AFTER header
				if (index % 2 !== 0) this.setSingleStyleForCells(worksheet, "fill", xlsxPerformStyles.oddFill, rowOffset);
			});

			worksheet.addRow();
			worksheet.addRow(["Family Name", "Value"]);
			rowOffset = worksheet.rowCount;
			this.setStylesForCells(worksheet, xlsxPerformStyles.headerStyles, rowOffset);

			this.progressivesList.forEach((item, index) => {
				// need unique copy of sales report to keep from mutating the prop that's passed to child component
				let data = Object.assign({}, item);

				worksheet.addRow([data.potFamilyName, this.systemCurrencyTool.formatFromAU(data.valueAU, this.systemFull)]);
				rowOffset = worksheet.rowCount;

				// Rows are not zero indexed and also need to skip header row so, index + rowOffset
				this.setStylesForCells(worksheet, xlsxPerformStyles.basicStyles, rowOffset);

				// Fill every even numbered row after header row.
				// Rows are not zero indexed so we actually are selecting for odd since we want the even rows AFTER header
				if (index % 2 !== 0) this.setSingleStyleForCells(worksheet, "fill", xlsxPerformStyles.oddFill, rowOffset);
			});

			worksheet.getColumn(1).alignment = "left";

			workbook.xlsx
				.writeBuffer()
				.then((data) => {
					const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" });
					saveAs(blob, `Liability_Report_${this.fileNameDateRange}.xlsx`);
				})
				.catch((e) => {
					this.status.message = `Failed to exprort spreadsheet: ${e}`;
					this.status.ok = false;
					this.eventBus.emit("updateStatus", this.status);
					this.serverComplete = true;
					console.error(e);
					return false;
				});
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#loading {
	display: grid;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 30px;
	background-color: rgb(0 0 0 / 70%);
	z-index: 9999;
	transition: background-color 0.3s ease-in-out;
	text-align: center;
	align-content: center;
}

.casino-selector {
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
