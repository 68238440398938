<template>
	<div id="performance-list-container">
		<table class="performance-list" v-if="gamePerformanceList.length > 0">
			<tr class="header-row sticky">
				<th>Title</th>
				<th>RTP</th>
				<th>Denom</th>
				<th>Plays</th>
				<th>Wins</th>
				<th>Progs Count</th>
				<th>Wager Regular</th>
				<th>Wager Promo</th>
				<th>Prog Contrib</th>
				<th>Paid Paytable</th>
				<th>Paid Prog</th>
				<th>Paytable RTP</th>
				<th>Actual RTP</th>
			</tr>
			<tr class="data-row" v-for="(item, index) in gamePerformanceList" :key="index" :value="item.id">
				<td class="left-align">{{ item.title }}</td>
				<td class="center-align">{{ item.nominalRtp }}%</td>
				<td class="right-align">{{ systemCurrencyTool.formatFromAU(item.denomAU, systemMinorWholeOrFull) }}</td>
				<td class="right-align">{{ item.countPlays }}</td>
				<td class="right-align">{{ item.countWins }}</td>
				<td class="right-align">{{ item.countProgressives }}</td>
				<td class="right-align">{{ systemCurrencyTool.formatFromAU(item.wagerRegularAU, systemFull) }}</td>
				<td class="right-align">{{ systemCurrencyTool.formatFromAU(item.wagerPromoAU, systemFull) }}</td>
				<td class="right-align">{{ systemCurrencyTool.formatFromAU(item.progressiveContributionsAU, systemFull) }}</td>
				<td class="right-align">{{ systemCurrencyTool.formatFromAU(item.paytablePrizeAU, systemFull) }}</td>
				<td class="right-align">{{ systemCurrencyTool.formatFromAU(item.progressivePrizeAU, systemFull) }}</td>
				<td class="right-align">{{ calculatePaytableRTP(item) }}%</td>
				<td class="right-align">{{ calculateActualRTP(item) }}%</td>
			</tr>
		</table>
		<div v-else>
			<h2 class="no-records">No Game Performance Records for this date range at {{ selectedCasino.name }}.</h2>
		</div>
	</div>
</template>

<script>
export default {
	name: "GamePerformanceTable",
	inheritAttrs: false,
	props: {
		selectedCasino: Object,
		gamePerformanceList: Array,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
	},
	data() {
		return {
			systemMinorWholeOrFull: this.systemCurrencyTool.displayType.minorWholeOrFull,
			systemFull: this.systemCurrencyTool.displayType.full,
		};
	},
	methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sticky {
	top: -16px;
}

#performance-list-container {
	position: relative;
	margin-bottom: 15px;
	padding: 15px;
	background-color: #ddd;
}

.performance-list {
	/* width: 100%; */
	text-align: left;
	border-collapse: collapse;
}

.performance-list th,
.performance-list td {
	border: 1px #000 solid;
	padding: 5px 10px;
}

.performance-list tr.data-row {
	color: #000;
}

.performance-list tr:nth-child(2n) {
	background-color: #bfbfbf;
}

.header-row {
	background-color: #525252;
	color: #fff;
}

.header-row th {
	border-color: #fff #fff #000;
}

.no-records {
	color: #000;
}

.right-align {
	text-align: right;
}

.left-align {
	text-align: left;
}

.center-align {
	text-align: center;
}
</style>
